body {
    margin: 0;
    font-family: 'Poppins';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-y: hidden;
}

::-moz-selection {
    /* Code for Firefox */
    color: red;
    background: yellow;
}

::selection {
    color: rgba(58, 180, 67, 1);
    background: rgba(58, 180, 67, 0.1);
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

a {
    text-decoration: none;
    color: #333;
    transition: opacity 0.15s ease-in-out;
}

a:hover {
    opacity: 50%;
    color: #333;
    transition: opacity 0.15s ease-in-out;
}

.alt-a {
    font-size: 13px;
    font-weight: 400;
    text-decoration: none;
    color: #444 !important;
    transition: all 0.15s ease-in-out;
}

.alt-a:hover {
    opacity: 75%;
    font-weight: 600;
    /* font-size: 16px; */
    color: #000 !important;
    /* color: #f87d4e !important; */
    transition: all 0.15s ease-in-out;
}


.tableContainer {
    background: #FFFFFF;
    box-shadow: 0px 16px 44px rgba(82, 82, 82, 0.05);
    border-radius: 20px;
    margin-top: 32px;
    overflow: hidden;
    position: relative;
    padding-bottom: 75px
}

.tableText {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: -0.02em;
    color: #404040;
    padding: 10px 0px;
}

.tableSearchBox {
    margin-top: -10px;
    width: 375px;
    height: 46px;
    background: #FFFFFF;
    border: 1px solid #D4D4D4;
    border-radius: 15px;
}

.searchText {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.02em;
    color: #A3A3A3;

}

.tableTitle {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: -0.02em;
    color: #404040;

}

.tableText {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: -0.02em;
    color: #262626;
    margin-bottom: -10px;
    margin-top: -10px;
}

.tableStatus {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: -0.02em;
    margin-top: -10px;
    margin-bottom: -10px;
    color: #3AB44D;
}

.tableAction {
    margin-top: -15px;
    margin-bottom: -15px;
    height: 32px;
    border-radius: 50px;
    padding: 0px 15px
}

.ant-table-thead>tr>th {
    background-color: white !important;
}

.ant-table-thead .ant-table-cell {
    font-weight: bold;
    padding-bottom: 6px !important;
}

.ant-table-cell {
    font-size: 13px !important;

}

#paymentQueueTable .ant-table-cell {
    padding: 12px !important;
}

.ant-table-thead>tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    background-color: inherit !important;
}

.ant-table-thead .ant-table-cell {
    font-weight: bold;
    padding-bottom: 10px !important;
}

.ant-table-thead .ant-table-cell {
    font-size: 13px !important;
    cursor: auto;
}

.ant-table-cell {
    font-size: 12px !important;
}

.ant-table-cell {
    cursor: pointer;
}

.ant-table-filter-column {
    justify-content: flex-start !important;
}

.ant-table-filter-column .ant-table-column-title {
    flex: inherit;
}

.ant-select .ant-select-selector {
    border-radius: 14px !important;
    padding: 3px 10px !important;
    height: 32px !important;
}

.ant-select-selector:has(#otp) {
    border-radius: 20px !important;
    /* height: 52px !important; */
    padding: 6px 17px !important;
    height: 46px !important;
}

.ant-select-selector:has(#select42px) {
    border-radius: 12px !important;
    padding: 5px 17px !important;
    height: 36px !important;
}

.ant-select-selector:has(#select42px)>.ant-select-selection-placeholder {
    margin-top: -5px;
}

.ant-select:has(#select42px)>.ant-select-arrow {
    margin-right: 5px !important;
    margin-top: -6px !important;

}

.ant-select-selector:has(#multiple-select42px) {
    border-radius: 20px !important;
    min-height: 36px !important;
    height: auto !important;
}


.ant-select:has(#multiple-select42px)>.ant-select-arrow {
    margin-right: 5px !important;
    margin-top: -8px !important;
}




.ant-select-selector:has(#roleSelect) {
    border: 1px solid #A3A3A3 !important;
    border-radius: 25px !important;
    /* height: 52px !important; */
    padding: 10px 25px !important;
    margin-top: -10px;
    height: 52px !important;
}

.ant-select:has(#roleSelect) .ant-select-arrow {
    margin-top: -10px !important;
    margin-right: 10px;
}

.ant-select-dropdown {
    border-radius: 17px;
}

.ant-select-item-option {
    border-radius: 10px !important;
}

.ant-collapse-header {
    padding: 5px !important;
}

.ant-collapse-header-text {
    height: 25px;

}

.ant-modal-content {
    border-radius: 25px !important;
    padding: 5px 35px !important;
    padding-bottom: 20px !important;
}

.ant-modal-content:has(#docModal) {
    border-radius: 25px !important;
    /* padding: 25px 50px !important; */
    background-color: white;
}

.ant-modal-content:has(#docModalDone) {
    border-radius: 25px !important;
    /* padding: 25px 50px !important; */
    padding: 0px !important;
    background-color: white;
    width: 540px;
}

.ant-modal-content:has(#no-padding-modal) {
    padding: 0px !important;
}

.ant-form-item-explain-error {
    margin-left: 15px;
}

.ant-radio-button-wrapper:where(.css-dev-only-do-not-override-16qhuaf).ant-radio-button-wrapper:not(:first-child)::before {
    display: none !important;
}

.ant-table-filter-dropdown {
    border-radius: 15px !important;
}

span:has(#dragger)>.ant-upload-list {
    width: 125px !important;
}

span:has(#dragger)>.ant-upload {
    height: 36px !important;
    border-radius: 12px !important;
}

.ant-upload.ant-upload-drag {
    border-radius: 20px !important;
}

.ant-table-cell {
    background-color: white !important;
}

#coloredTable .ant-table-cell {
    background-color: #fafafa !important;
}

#autoComplete .ant-select-selector {
    height: 46px;
    border-radius: 20px !important;
    padding: 7px 15px;
}

.ant-picker-panel-container {
    border-radius: 20px !important;
}

#autoComplete .ant-select-selection-search-input {
    padding: 5px;
    padding-top: 18px;
}

.input {
    border: 1px solid #A3A3A3 !important;
    border-radius: 25px !important;
    /* height: 52px !important; */
    padding: 15px 25px !important;
    margin-top: -10px;
}

.headerText {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: -0.02em;
    color: #171717;
    margin-top: 15px;
}

.breadCrumb1 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    /* identical to box height, or 143% */

    display: flex;
    align-items: center;
    text-align: center;

    /* Black/100% */

    color: #1C1C1C;
    opacity: 70%;
}

.breadCrumb2 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    /* identical to box height, or 143% */

    display: flex;
    align-items: center;
    text-align: center;

    color: #1C1C1C;
}

.ant-select-selector:has(#autoComplete) {
    height: 42px !important;
    border-radius: 18px !important;
    font-size: 14px !important;
}

.ant-select-selection-placeholder {
    line-height: 34px !important;
}

.cardShadow {
    background: #FFFFFF;
    box-shadow: 0px 16px 44px rgba(82, 82, 82, 0.1);
    border-radius: 20px;
    padding: 25px;
    transition: all 0.25s ease-in-out;
    position: relative;
}

.ant-popover-inner {
    padding: 12px !important;
    border-radius: 25px !important;
    box-shadow: 0px 16px 44px rgba(82, 82, 82, 0.2) !important;
}

.ant-select-selection-item {
    border-radius: 10px !important;
    line-height: 22px !important;
}

.ant-upload-drag .ant-upload {
    padding: 0px !important;
}

.ant-dropdown-menu {
    border-radius: 20px !important;
}

.ant-dropdown-menu-item {
    border-radius: 15px !important;
}

#filter-box .ant-dropdown-menu {
    box-shadow: none;
}

.ant-upload-select {
    border-radius: 20px !important;
}

.ant-upload-list-item {
    border-radius: 20px !important;
}

.ant-upload-list-item-thumbnail {
    border-radius: 15px !important;
}

.ant-upload-list-item-image:hover {
    border-radius: 15px !important;
}

#dropdownFilter .ant-dropdown-menu {
    box-shadow: none !important;
}

#popover-no-padding {
    padding: 0px !important;
}

#popover-no-padding .ant-menu-item {
    height: 32px !important;
    line-height: 32px !important;
    border-radius: 15px;
}

#popover-no-padding .ant-menu {
    padding-right: 25px;
}